<template>
  <div class="payproduct">
    <v-row class="pa-6 mt-4">
      <v-btn-toggle v-model="tap" class="transparent" mandatory>
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="0"
          text
          outlined
          style="width: 110px; height: 40px"
          >개요</v-btn
        >
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="1"
          text
          outlined
          style="width: 110px; height: 40px"
          >일반패키지</v-btn
        >
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="2"
          text
          outlined
          style="width: 110px; height: 40px"
          v-if="user.email !== 'test@supermembers.co.kr'"
          >결제내역</v-btn
        >
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="3"
          text
          outlined
          style="width: 110px; height: 40px"
          v-if="user.email !== 'test@supermembers.co.kr'"
          >충전금</v-btn
        >
        <v-btn
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="4"
          text
          outlined
          style="width: 110px; height: 40px"
          v-if="user.email !== 'test@supermembers.co.kr'"
          >충전내역</v-btn
        >
      </v-btn-toggle>
    </v-row>
    <v-row class="ml-1 pa-6">
      <v-col cols="12" v-if="tap === 0">
        <!--        <v-row>-->
        <!--          <v-col cols="12" md="4" class="mb-4">-->
        <!--            <h2 class="headline">모집 가능 인원</h2>-->
        <!--            <v-divider class="my-4" width="36" />-->
        <!--            <div>-->
        <!--              <span class="display-1 secondary&#45;&#45;text text-center mt-10">{{ (packages?.[0]?.balance || 0) | numFormat }}명</span>-->
        <!--            </div>-->
        <!--          </v-col>-->
        <!--          <v-col cols="12" md="4">-->
        <!--            <h2 class="headline">충전금 잔액</h2>-->
        <!--            <v-divider class="my-4" width="36" />-->
        <!--            <div>-->
        <!--              <span class="display-1 secondary&#45;&#45;text text-center mt-10">₩{{ (sumemPoints?.[0]?.balance || 0) | numFormat }}</span>-->
        <!--            </div>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
        <v-row>
          <v-col cols="12">
            <div class="body-1 font-weight-bold mt-6 mb-2">일반 패키지</div>
            <v-data-table
              :headers="[
                { text: '광고명', width: '136px', sortable: false },
                { text: '희망 인원', width: '80px', sortable: false },
                { text: '이용 건수', width: '80px', sortable: false },
                { text: '진행 여부', width: '80px', sortable: false },
                { text: '시작일', width: '120px', sortable: false },
                { text: '종료일', width: '120px', sortable: false },
              ]"
              :items="paycardsSorted"
              :items-per-page="5"
              mobile-breakpoint="0"
              style="
                backgroud-color: transparent;
                border-top: 1px solid rgba(0, 0, 0, 0.12);
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
              ">
              <template v-slot:item="props">
                <tr style="font-size: 13px" v-if="props.item">
                  <td class="pr-2 pl-0" style="width: 250px">
                    {{ props.item.order }}차]{{ props.item.channel === 'insta' ? '인스타그램' : '네이버블로그' }}/{{
                      props.item.adsname
                    }}
                  </td>
                  <td class="px-1">{{ props.item.checkBloggerCnt }}</td>
                  <td class="px-1">
                    {{ props.index === 0 ? props.item.contractCount + '(진행중)' : props.item.contractCount }}
                  </td>
                  <td class="pl-2 pr-0">
                    {{ props.item.contractCount >= props.item.checkBloggerCnt ? '완료' : '진행중' }}
                  </td>
                  <td class="px-1">
                    {{ $moment(props.item.adsStart).tz('Asia/Seoul').format('YYYY-MM-DD') }}
                  </td>
                  <td class="pl-2 pr-0">{{ props.item.nextPaidAt }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row v-if="user.email !== 'test@supermembers.co.kr'">
          <v-col cols="12">
            <div class="body-1 font-weight-bold mt-6 mb-2">프리미엄 패키지</div>
            <v-data-table
              :headers="[
                { text: '광고명', width: '136px', sortable: false },
                { text: '희망 인원', width: '80px', sortable: false },
                { text: '신청 건수', width: '80px', sortable: false },
                { text: '진행 여부', width: '80px', sortable: false },
                { text: '시작일', width: '120px', sortable: false },
                { text: '종료일', width: '120px', sortable: false },
              ]"
              :items="paycardsSortedPremium"
              :items-per-page="5"
              mobile-breakpoint="0"
              style="
                backgroud-color: transparent;
                border-top: 1px solid rgba(0, 0, 0, 0.12);
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
              ">
              <template v-slot:item="props">
                <tr style="font-size: 13px" v-if="props.item">
                  <td class="pr-2 pl-0" style="width: 250px">
                    {{ props.item.order }}차]{{ props.item.channel === 'insta' ? '인스타그램' : '네이버블로그' }}/{{
                      props.item.adsname
                    }}
                  </td>
                  <td class="px-1">
                    {{ props.item.number || props.item.checkBloggerCnt }}
                  </td>
                  <td class="px-1">
                    {{ props.index === 0 ? props.item.contractCount + '(진행중)' : props.item.contractCount }}
                  </td>
                  <td class="pl-2 pr-0">
                    {{ props.item.contractCount >= props.item.checkBloggerCnt ? '완료' : '진행중' }}
                  </td>
                  <td class="px-1">
                    {{ $moment(props.item.adsStart).tz('Asia/Seoul').format('YYYY-MM-DD') }}
                  </td>
                  <td class="pl-2 pr-0">{{ props.item.nextPaidAt }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="tap === 1">
        <v-row v-if="user.email !== 'test@supermembers.co.kr'">
          <v-col cols="6" class="pr-4 hidden-sm-and-down">
            <h2 class="headline">패키지 구매</h2>
            <v-divider class="my-4" width="36" />
            <div>
              <span class="text-center mt-10">패키지를 구매하시면 인원을 추가 하실 수 있습니다.</span>
            </div>
            <div>
              <span class="text-center">현금 결제만 가능하며, 하단 카톡채널로 충전 요청을 부탁드립니다.</span>
            </div>
          </v-col>
          <v-col cols="12" md="3" class="mb-4">
            <h2 class="headline">남은 기간</h2>
            <v-divider class="my-4" width="36" />
            <div>
              <span class="display-1 secondary--text text-center mt-10"
                >{{ packages?.[0]?.remaining_days | numFormat }}일</span
              >
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <h2 class="headline">모집 가능 인원</h2>
            <v-divider class="my-4" width="36" />
            <div>
              <span class="display-1 secondary--text text-center mt-10"
                >{{ (packages?.[0]?.balance || 0) | numFormat }}명</span
              >
            </div>
          </v-col>
          <v-col cols="12" class="pr-4 hidden-md-and-up mt-4">
            <div>
              <span class="text-center mt-10">패키지를 구매하시면 인원을 추가 하실 수 있습니다.</span>
            </div>
            <div>
              <span class="text-center">현금 결제만 가능하며, 하단 카톡채널로 충전 요청을 부탁드립니다.</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-10">
          <v-col cols="6" md="3" class="px-1">
            <v-row
              class="pa-3"
              style="border-radius: 8px; border: 1px solid rgba(0, 0, 0, 0.12); width: 155px; height: 252px">
              <v-col class="ml-2">
                <v-chip color="rouge" class="text-center mt-2" outlined small>{{
                  user.email !== 'test@supermembers.co.kr' ? '체험형 패키지' : '일반 패키지'
                }}</v-chip>
                <v-row class="my-2 subheading">인당 15,000원</v-row>
                <v-row class="my-1 caption">기간 1개월</v-row>
                <v-row class="my-1 caption">인원 10명</v-row>
                <v-row class="my-1 caption">가격 15만원</v-row>
                <v-row class="my-1 caption" style="color: #808080">VAT 별도</v-row>
                <v-btn class="rouge white-two--text mx-0" style="width: 113px" @click="pay(150000, 'package')"
                  >구매하기</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" md="3" class="px-1 mb-2" v-if="user.email !== 'test@supermembers.co.kr'">
            <v-row class="pa-3 pt-7" style="border-radius: 8px; border: 1px solid #ea2a3a; width: 155px; height: 252px">
              <v-col
                class="ml-2"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'min-height:172px; border-radius: 8px 8px 0px 0px'
                    : 'min-height:180px; border-radius: 8px 0px 0px 8px'
                ">
                <div
                  class="caption white--text"
                  :style="$vuetify.breakpoint.mdAndUp ? 'margin-left:60px' : 'margin-left:32px'"
                  style="
                    margin-top: -44px;
                    width: 55px;
                    height: 27px;
                    border-radius: 18px;
                    background-color: #ea2a3a;
                    text-align: center;
                    vertical-align: middle;
                    line-height: 27px;
                  ">
                  BEST
                </div>
                <v-chip color="rouge" class="text-center mt-2" outlined small>100만원 패키지</v-chip>
                <v-row class="my-2 subheading rouge--text">인당 10,000원</v-row>
                <v-row class="my-1 caption">기간 <span class="rouge--text">&nbsp;6개월</span></v-row>
                <v-row class="my-1 caption">인원 <span class="rouge--text">&nbsp;100명</span></v-row>
                <v-row class="my-1 caption">가격 100만원</v-row>
                <v-row class="my-1 caption" style="color: #808080">VAT 별도</v-row>
                <v-btn class="rouge white-two--text mx-0" style="width: 113px" @click="pay(1000000, 'package')"
                  >구매하기</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" md="3" class="px-1" v-if="user.email !== 'test@supermembers.co.kr'">
            <v-row
              class="pa-3"
              style="border-radius: 8px; border: 1px solid rgba(0, 0, 0, 0.12); width: 155px; height: 252px">
              <v-col class="ml-2">
                <v-chip color="rouge" class="text-center mt-2" outlined small>500만원 패키지</v-chip>
                <v-row class="my-2 subheading">인당 7,500원</v-row>
                <v-row class="my-1 caption">기간 12개월</v-row>
                <v-row class="my-1 caption">인원 670명</v-row>
                <v-row class="my-1 caption">가격 500만원</v-row>
                <v-row class="my-1 caption" style="color: #808080">VAT 별도</v-row>
                <v-btn class="rouge white-two--text mx-0" style="width: 113px" @click="pay(5000000, 'package')"
                  >구매하기</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" md="3" class="px-1" v-if="user.email !== 'test@supermembers.co.kr'">
            <v-row
              class="pa-3"
              style="border-radius: 8px; border: 1px solid rgba(0, 0, 0, 0.12); width: 155px; height: 252px">
              <v-col class="ml-2">
                <v-chip color="rouge" class="mt-2" outlined small>1,000만원 패키지</v-chip>
                <v-row class="my-2 subheading">인당 5,000원</v-row>
                <v-row class="my-1 caption">기간 12개월</v-row>
                <v-row class="my-1 caption">인원 2,000명</v-row>
                <v-row class="my-1 caption">가격 1,000만원</v-row>
                <v-row class="my-1 caption" style="color: #808080">VAT 별도</v-row>
                <v-btn class="rouge white-two--text mx-0" style="width: 113px" @click="pay(10000000, 'package')"
                  >구매하기</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="body-1 font-weight-bold mt-6 mb-2">충전•환불•소진 내역</div>
            <v-data-table
              :headers="packageHeaders"
              :items="packages"
              item-key="id"
              class="mt-3"
              disable-sort
              :items-per-page="5"
              mobile-breakpoint="0"
              style="
                background-color: transparent;
                border-top: 1px solid rgba(0, 0, 0, 0.12);
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
              ">
              <template v-slot:item.status="{ item }">
                {{ item.adId ? '소진' : '충전' }}
              </template>
              <template v-slot:item.content="{ item }">
                {{ item.name || '-' }}
              </template>
              <template v-slot:item.number="{ item }">
                {{ (item.adId ? -item.number : item.number) || '-' }}명
              </template>
              <template v-slot:item.balance="{ item }"> {{ item.balance | numFormat }}명 </template>
              <template v-slot:item.price="{ item }"> ₩{{ Math.abs(item.price) | numFormat }} </template>
              <template v-slot:item.createdAt="{ item }">
                {{ $moment(item.createdAt).format('YYYY.MM.DD') }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="tap === 2">
        <v-row>
          <v-col cols="12">
            <div class="body-1 font-weight-bold mt-6 mb-2">결제 내역</div>
            <v-data-table
              :headers="[
                { text: '광고명', width: '136px', sortable: false },
                { text: '이용 건수', width: '80px', sortable: false },
                { text: '기간', width: '120px', sortable: false },
                { text: '결제일', width: '120px', sortable: false },
              ]"
              :items="paycardsSorted"
              :items-per-page="5"
              mobile-breakpoint="0"
              style="
                background-color: transparent;
                border-top: 1px solid rgba(0, 0, 0, 0.12);
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
              ">
              <template v-slot:item="props">
                <tr style="font-size: 13px" v-if="props.item">
                  <td class="pr-2 pl-0" style="width: 250px">
                    {{ props.item.order }}차]{{ props.item.channel === 'insta' ? '인스타그램' : '네이버블로그' }}/{{
                      props.item.adsname
                    }}
                  </td>
                  <td class="px-1">
                    {{ props.index === 0 ? props.item.contractCount + '(진행중)' : props.item.contractCount }}
                  </td>
                  <td class="px-1">
                    {{ $moment(props.item.adsStart).tz('Asia/Seoul').format('YYYY-MM-DD') }}
                    -
                    {{ props.item.nextPaidAt }}
                  </td>
                  <td class="pl-2 pr-0">
                    {{
                      $moment(props.item.paidAt || props.item.adsStart)
                        .tz('Asia/Seoul')
                        .format('YYYY-MM-DD')
                    }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="tap === 3">
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="8" class="hidden-sm-and-down">
              <h2 class="font-weight-bold headline">충전금액 관리</h2>
              <v-divider class="my-4" width="36" />
              <div>금액을 충전하고 블로거에게 환급금 및 포인트를 지급해보세요.</div>
            </v-col>
            <v-col cols="12" md="4">
              <h2 class="headline">잔액</h2>
              <v-divider class="my-4" width="36" />
              <v-row class="display-1 secondary--text mt-10">
                <v-col> ₩{{ (sumemPoints?.[0]?.balance || 0) | numFormat }} </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-card flat>
            <v-row class="subheading">잔액 충전하기</v-row>
            <v-row class="caption">현재 잔액 충전은 카드로만 가능합니다.</v-row>
            <v-divider class="my-4"></v-divider>
            <v-row class="body-2">
              <v-col :cols="$vuetify.breakpoint.mdAndUp ? '7' : '12'">
                <div class="mb-2">충전금액</div>
                <v-chip v-for="(amount, idx) in [5, 10, 50, 100]" :key="idx" @click="price = price + amount * 10000">
                  +{{ amount }}만원
                </v-chip>
                <v-text-field
                  v-model.number="price"
                  name="price"
                  type="number"
                  suffix="원"
                  class="mt-2 mb-6"
                  hint="충전하실 금액을 추가하거나 직접 입력해주세요."
                  persistent-hint
                  single-line
                  outlined
                  required
                  >직접 입력</v-text-field
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="mb-2">결제 금액</div>
                <div class="secondary--text subheading">₩{{ Math.floor(price * 1.1) | numFormat }}</div>
                <div class="my-3" style="color: #757575">결제 금액은 부가세 10%가 포함된 금액입니다.</div>
              </v-col>
            </v-row>
            <v-btn
              color="primary"
              @click="pay(_.isNumber(price) ? price : Number(price.replace(/[^\d]/g, '')), 'members')"
              style="margin: 0"
              >결제하기</v-btn
            >
          </v-card>
        </v-col>
      </v-col>
      <v-col cols="12" v-if="tap === 4">
        <v-row>
          <v-col cols="12">
            <div class="body-1 font-weight-bold mt-6 mb-2">충전•환불•소진 내역</div>
            <v-data-table
              :headers="chargeHeaders"
              :items="sumemPoints"
              item-key="id"
              class="mt-3"
              disable-sort
              :items-per-page="5"
              mobile-breakpoint="0"
              style="
                background-color: transparent;
                border-top: 1px solid rgba(0, 0, 0, 0.12);
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
              ">
              <template v-slot:item.createdAt="{ item }">
                {{ item.createdAt.split('T')[0] }}
              </template>
              <template v-slot:item.status="{ item }">
                {{ getChargeType(item) }}
              </template>
              <template v-slot:item.notes="{ item }">
                {{ item.pointType ?? '-' }}
              </template>
              <template v-slot:item.amount="{ item }"> ₩{{ Math.abs(item.amount) | numFormat }} </template>
              <template v-slot:item.balance="{ item }"> ₩{{ item.balance | numFormat }} </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import companyMixin from '@/components/shared/companyMixin';
import userMixin from '@/components/shared/userMixin';
import BootPay from 'bootpay-js';
import { EventBus } from '@/components/shared/event-bus';
import { getChargeType } from '@/utils';

export default {
  name: 'payproduct',
  mixins: [userMixin, companyMixin],
  props: ['option'],
  data() {
    return {
      packageHeaders: [
        { text: '상태', value: 'status', width: '60px' },
        { text: '내용', value: 'content', width: '150px' },
        { text: '인원', value: 'number', width: '80px' },
        { text: '모집 가능 인원', value: 'balance', width: '110px' },
        { text: '결제 금액', value: 'price', width: '136px' },
        { text: '일시', value: 'createdAt', width: '136px' },
      ],
      chargeHeaders: [
        { text: '일시', value: 'createdAt', width: '136px' },
        { text: '상태', value: 'status', width: '136px' },
        { text: '내용', value: 'notes', width: '136px' },
        { text: '금액', value: 'amount', width: '136px' },
        { text: '잔액', value: 'balance', width: '136px' },
      ],
      packages: [],
      premiumPackages: [],
      sumemPoints: [],
      shortfall: 0,
      tap: 0,
      paycards: [],
      paycardsPremium: [],
      price: 0,
    };
  },
  computed: {
    paycardsSorted() {
      return this._.orderBy(this.paycards, [card => card.paidAt || card.adsStart], ['desc']).filter(
        paycard => paycard.payState === 1
      );
    },
    paycardsSortedPremium() {
      return this._.orderBy(this.paycardsPremium, [card => card.paidAt || card.adsStart], ['desc']).filter(
        paycard => paycard.payState === 1
      );
    },
  },
  methods: {
    getChargeType,
    pay(price, type) {
      let payPrice = Math.floor(price * 1.1);
      console.log(this.user.id + '_' + type + '_' + new Date().getTime());
      BootPay.request({
        price: payPrice, //실제 결제되는 가격
        application_id: '5f20860202f57e0033305324',
        name: '인플루언서 마케팅 상품', //결제창에서 보여질 이름
        pg: 'inicis',
        method: 'card',
        show_agree_window: 0, // 부트페이 정보 동의 창 보이기 여부
        items: [
          {
            item_name: '인플루언서 마케팅 상품', //상품명
            qty: 1,
            unique: '0',
            price: payPrice,
            cat1: '마케팅',
            cat2: '마케팅',
            cat3: '마케팅',
          },
        ],
        user_info: {
          username: this.user.name,
          email: this.user.email,
          addr: '',
          phone: this.user.contacts.replace(/[^0-9]/g, ''),
        },
        order_id: this.user.id + '_' + type + '_' + new Date().getTime(),
        params: { date: new Date(), companyId: this.user.id },
        extra: {
          theme: 'purple',
        },
      })
        .error(data => {
          console.log(data);
          this.$store.commit('setMsg', data.msg);
          this.$store.commit('setShowSnackbar', true);
        })
        .cancel(async data => {
          console.log(data);
        })
        .confirm(data => {
          if (data.params.companyId === this.user.id) {
            BootPay.transactionConfirm(data);
          } else {
            BootPay.removePaymentWindow();
          }
        })
        .close(data => {
          console.log(data);
        })
        .done(async data => {
          let charge = {
            companyId: data.params.companyId,
            card: data.card_name,
            amount: price, //data.price,
            status: data.status,
            method: data.method,
            order: data.order_id,
            receiptId: data.receipt_id,
            receiptUrl: data.receipt_url,
          };
          try {
            if (type === 'package') {
              charge.number = Math.floor({ 15: 10, 100: 100, 500: 670, 1000: 2000 }[price / 10000]);
              charge.period = { 15: 1, 100: 6, 500: 12, 1000: 12 }[price / 10000];
              charge.name = {
                15: '체험형 패키지',
                100: '100만원 패키지',
                500: '500만원 패키지',
                1000: '1000만원 패키지',
              }[price / 10000];
              let res = await this.axios.post('company/user/package', charge);
              if (res.status === 200) {
                this.packages.unshift(res.data);
              }
            } else if (type === 'premium') {
              charge.number = Math.floor({ 25: 5, 100: 25, 300: 100 }[price / 10000]);
              charge.period = { 25: 12, 100: 12, 300: 12 }[price / 10000];
              charge.name = {
                25: '프리미엄 체험형 패키지',
                100: '프리미엄 100만원 패키지',
                300: '프리미엄 300만원 패키지',
              }[price / 10000];
              let res = await this.axios.post('company/user/premium', charge);
              if (res.status === 200) {
                this.premiumPackages.unshift(res.data);
              }
            } else {
              let res = await this.axios.post('/company/user/sumempoint', charge);
              if (res.status === 200) {
                this.points.unshift(res.data);
              }
            }
          } catch (e) {
            console.log(e);
          }
          if (this.user) {
            EventBus.$emit('update:point');
          }
        });
    },
  },
  async mounted() {
    let packages = await this.axios.get(`company/user/package?companyId=${this.user.id}&orderBy=id&direction=desc`);
    this.packages = packages.data.packages;
    let premiumPackages = await this.axios.get(
      `company/user/premium?companyId=${this.user.id}&orderBy=id&direction=desc`
    );
    this.premiumPackages = premiumPackages.data.packages;
    let sumemPoints = await this.axios.get(
      `company/user/sumempoint?companyId=${this.user.id}&orderBy=id&direction=desc`
    );
    this.sumemPoints = sumemPoints.data.sumemPoints;
    // this.sumemPoints = this.sumemPoints.filter((sumemPoint) => {
    //   return (!sumemPoint.contractId && sumemPoint.amount > 0)
    // });

    this.shortfall =
      this._.sum(
        this.companies.map(company => {
          console.log(company.id, company.naver?.available);
          if (
            company.deliveryMethod === 'PR' &&
            (this._.isNil(company.naver?.available) || company.naver?.available < 0)
          ) {
            return company.naver.blackPayment * company.naver.number * 1.05;
          } else {
            return 0;
          }
        })
      ) - (this.sumemPoints?.[0]?.balance || 0);

    this.paycards.splice(0, Number.MAX_VALUE);
    this.paycardsPremium.splice(0, Number.MAX_VALUE);
    this.companies.map(async company => {
      if (company.category.firstName === '제품') {
        let paycards = await this.axios.get('/company3/paycard?companyId=' + company.id);
        paycards.data.paycards.forEach(paycard => {
          paycard.adsname = company.name;
          if (company.naver.number) {
            paycard.number = company.naver.number;
          }
          // this.$set(this.paycards, idx, paycard);
          if (this.$moment.tz('Asia/Seoul').isAfter(paycard.adsStart)) {
            if (company.type === 'A') {
              this.paycardsPremium.push(paycard);
            } else {
              this.paycards.push(paycard);
            }
          }
        });
      }
    });
  },
};
</script>
